<template>
    <van-overlay :show="requestConfirmShow" @click="requestConfirmShow = false">
      <div @click.stop>
        <div class="content">
          <div style="position: relative">
            <van-image class="content-img" :src="require('@/assets/img/request-confirm.png')" />
            <div class="x-close-dialog" >
              <van-image width="20px" @click="requestConfirmShow=false" :src="require('@/assets/img/x.png')">
              </van-image>
            </div>
          </div>
          <div class="text-father">
            <p>您选择了<span class="text-p">【{{}}】</span>服务，是否确认请求?</p>
          </div>
          <div class="button-father">
            <van-button class="bottom-button" round @click="requestConfirmShow = false">
              取消</van-button>
            <van-button class="bottom-button" round type="info" @click="toServiceApply" :disabled="agree === false">
              确认</van-button>
          </div>
          <div class="bottom-agree" v-if="showDisclaimer">
            <van-checkbox v-model="agree" shape="square" icon-size="15px">
                阅读并同意本服务
              <a @click="toDislaimer" class="bottom-disclaimer">免责声明</a>
            </van-checkbox>
          </div>
        </div>
      </div>
    </van-overlay>
</template>

<script>
export default {
  name: "requestConfirm",
  data() {
    return {
      requestConfirmShow: true,
      agree: false,
      showDisclaimer: true
    }
  },
  mounted() {
    this.isShow()
  },
  methods: {
    isShow(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/app/appserviceapply/isExist'),
        method: "post",
        data: this.$http.adornData({
          applyUserId: this.$userId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.showDisclaimer = false
          this.agree = true
        }
      })
    },
    toServiceApply() {
      //申请服务
      this.$router.push({path: '/service-apply'})
    },
    toDislaimer() {
      this.$router.push({path: '/disclaimer'})
    }
  }

}
</script>

<style scoped>
.content {
  height: 11rem;
  width: 8rem;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
}
.content-img {
  width: 100%;
  margin: 0 auto;
}
.x-close-dialog {
  font-size: 0.6rem;
  position: absolute;
  top: 0.4rem;
  right: 0.5rem;
}
.text-father {
  font-size: 0.5rem;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  text-align: left;
  line-height: 0.8rem;
  margin-top: 0.5rem;
  padding: 0 1rem 0;
}
.text-p {
  color: #F69A4A;
}
.button-father {
  margin-top: 0.8rem;
  padding: 0 0.5rem;
  display: flex;
  justify-content: space-around;
}
.bottom-button {
  width: 2.5rem;
  border: 1px #C6C6C6 solid;
}
.bottom-agree {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.3rem;
}
.bottom-disclaimer {
  text-decoration: underline;
  color: #0C8DF7;
}
</style>
